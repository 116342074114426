import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BillingService, InsuranceService } from '@insureshop/core/services';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html'
})
export class PaymentStatusComponent implements OnInit {

  constructor(private insuranceService: InsuranceService,
              private billingService: BillingService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.has('id')) {
      const id = +this.route.snapshot.queryParamMap.get('id');
      const module = this.route.snapshot.queryParamMap.get('module');
      let payment;

      if (module === 'insurance') {
        payment = this.insuranceService.checkPaymentStatus(id);

      } else if (module === 'billing') {
        payment = this.billingService.checkPaymentStatus(id);

      } else {
        this.router.navigate(['../failed'], {
          queryParams: { id },
          relativeTo: this.route
        });

      }

      payment.subscribe(res => {
        if (res.status === 'PAID') {
          this.router.navigate(['../success'], {
            queryParams: {id, module},
            relativeTo: this.route
          });
        } else if (res.status === 'PENDING') {
          this.router.navigate(['../pending'], {
            relativeTo: this.route
          });
        } else {
          this.router.navigate(['../failed'], {
            queryParams: { id },
            relativeTo: this.route
          });
        }
      });

    }
  }
}
