import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class AppConfigProvider {
    config: any;
    constructor(private http: HttpClient) { }
    
    async loadConfig() {
        try {
            const config = await this.http.get('/assets/config/config.json').toPromise()
            this.config = config
        } catch (err) {
            console.error(err)
            this.config = {}
        }
    }
    
    getConfig() {
        return this.config
    }
}