import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@insureshop/core/core.module';
import { PageNotFoundComponent } from '@insureshop/shared/components/404/page-not-found.component';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { CookieConfig } from '../assets/config/cookie-config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PublicModule } from './public/public.module';
import { AppConfigProvider } from './shared/providers/app-config.provider';


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    CoreModule,
    PublicModule,
    RouterModule,
    NgcCookieConsentModule.forRoot(CookieConfig),
    AppRoutingModule,
    MatTableModule,
    MatSnackBarModule,
    MatProgressSpinnerModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: (appConfig: AppConfigProvider) => { return () => appConfig.loadConfig() }, deps: [AppConfigProvider], multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
