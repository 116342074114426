import { Name } from './name.model';
import { Payor } from './payor.model';

export class PolicyDetail {
  constructor(
    public id: number,
    public insuranceId: number,
    public productVarianceName: string,
    public productName: string,
    public insured: string,
    public owner: string,
    public startDate: Date,
    public endDate: Date,
    public price: number,
    public policyNumber: string,
    public receiptNumber: string,
    public policyHolders?: {
      name: Name,
      policyNumber: string
    }[],
    public policyHolder?: {
      name: Name,
      policyNumber: string
    },
    public motorBrand?: string,
    public motorModelAndCode?: string,
    public motorYear?: string
  ) {}
}
