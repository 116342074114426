import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { ConfigService } from '@insureshop/core/services/config.service';
import { User } from '@insureshop/shared/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-secure-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent { 
  bdoInsureLink: string;

  constructor(private configService: ConfigService) {}

  ngOnInit() {
    this.bdoInsureLink = this.configService.getBdoInsureConfig().url;
  }

  public goBack() {
    window.location.href = this.bdoInsureLink;
  }
}
