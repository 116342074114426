import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ConfirmationModalComponent } from '@insureshop/shared/components/modals';
import { Components } from '@insureshop/shared/components/wizard';
import { MaterialModule } from '@insureshop/shared/modules/material.module';
import { MobiscrollModule } from '@insureshop/shared/modules/mobiscroll.module';
import { NgBootstrapModule } from '@insureshop/shared/modules/ng-bootstrap.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgBusyModule } from 'ng-busy';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PublicLayoutComponent } from './components/layout/base/public-layout.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { NavBarComponent } from './components/layout/nav-bar/nav-bar.component';
import { TopBarComponent } from './components/layout/top-bar/top-bar.component';
import { ExistingCustomerComponent } from './components/modals/existing-customer/existing-customer.component';
import { OnlineTermsConditionsModalComponent } from './components/modals/online-terms-conditions-modal/online-terms-conditions-modal.component';
import { ResendLinkComponent } from './components/modals/resend-link/resend-link.component';

@NgModule({
  declarations: [
    PublicLayoutComponent,
    NavBarComponent,
    TopBarComponent,
    FooterComponent,
    ConfirmationModalComponent,
    ...Components,
    ResendLinkComponent,
    ExistingCustomerComponent,
    OnlineTermsConditionsModalComponent,
  ],
  entryComponents: [
    ResendLinkComponent,
    ExistingCustomerComponent,
    OnlineTermsConditionsModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    MobiscrollModule,
    NgBootstrapModule,
    NgxDatatableModule,
    NgBusyModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
    PdfViewerModule,
    NgZorroAntdModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    MobiscrollModule,
    NgBootstrapModule,
    NgxDatatableModule,
    NgBusyModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
    PublicLayoutComponent,
    TopBarComponent,
    FooterComponent,
    ConfirmationModalComponent,
    NgZorroAntdModule,
    ...Components
  ]
})
export class SharedModule {
}
