import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UtilService } from '@insureshop/core/services';
import { Gender } from '@insureshop/modules/insurance/enums';

@Component({
  selector: 'app-gender-selection',
  templateUrl: './gender-selection.component.html',
  styleUrls: ['./gender-selection.component.scss']
})
export class GenderSelectionComponent implements OnInit {

  @Input() control: FormControl;

  public options: object;

  constructor(private util: UtilService) { }

  ngOnInit() {
    this.options = this.util.enumToArray(Gender);
  }

}
