import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { HealthInsurance, Product, TravelWizard } from '@insureshop/shared/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '@insureshop/core/services/config.service';

@Component({
  selector: 'app-product-quotations',
  templateUrl: './product-quotations.component.html',
  styleUrls: ['./product-quotations.component.scss']
})
export class ProductQuotationsComponent implements OnChanges {

  @Input() data: TravelWizard | HealthInsurance;
  @Input() products: Product[];
  @Input() annualPlans: Product[];
  @Output() select: EventEmitter<any> = new EventEmitter();

  public knowMoreDetails: Product;
  public loadMore = false;
  public moreProducts: Product[];
  public isDomestic: boolean;

  constructor(private modalService: NgbModal, private configService: ConfigService) { }

  ngOnChanges() {
    if (this.annualPlans && this.annualPlans.length > 0) {
      this.moreProducts = this.annualPlans;
    } else if (this.products && this.products.length > 3) {
      this.moreProducts = [...this.products].slice(3);
    }
  }

  public open(content, product) {
    this.modalService.open(content, {
      backdrop: true,
      keyboard: false,
      size: 'lg'
    });
    this.knowMoreDetails = product;
  }

  submit(variant: Product) {
    // this.pxSvc.eventEmitter(
    //   'trackCustom',
    //   variant.productName === 'MediCash' ? 'AddToCartMediCash' : 'AddToCartSafeTrip',
    //   {
    //     value: variant.productName === 'MediCash' ? variant.healthProductVariancePrice.price : variant.initialPrice,
    //     currency: 'PHP'
    //   }
    // );
    this.select.emit(variant);
  }

  isRecommended(): number {
    if (this.data instanceof TravelWizard) {
      if (this.products.length > 1) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  isVisaRequired(index: number): boolean {
    if (this.data instanceof TravelWizard) {

    }
    return true;
  }

  getInclusionAmount(tableOfCoverages: any[], description: string): string {
    this.isDomestic = this.annualPlans && this.products.filter(product => product.name.includes('Domestic')).length > 0;
    const coverages = tableOfCoverages.filter(coverage => coverage.description.includes(description));
    if (coverages.length > 0) {
      return coverages[0].value;
    } else {
      return null;
    }
  }

  getTravelInclusionAmount(tableOfCoverages: any[], description: string): string {
    this.isDomestic = this.annualPlans && this.products.filter(product => product.name.includes('Domestic')).length > 0;
    const coverages = tableOfCoverages.filter(coverage => coverage.description.includes(description));
    if (coverages.length > 0) {
      return coverages[0].value.replace('Up to PHP ', 'P');
    } else {
      return null;
    }
  }

  getAreaOfCoverage(product: any) {
    const { name } = product;
    switch (name) {
      case 'ASEAN Plus':
        return 'Southeast Asia, China, HK, Macau, Taiwan';

      case 'Domestic':
        return 'Within the Philippines';

      default:
        return 'International Coverage'
    }
  }

  isFlightDelayIncluded(scopes: any[]): boolean {
    const flightDelayScopes = scopes.filter(scope => scope.description.includes('Flight delay'));
    if (flightDelayScopes.length > 0) {
      return flightDelayScopes[0].isIncluded;
    } else {
      return false;
    }
  }

  isBaggageLossAndDamageIncluded(scopes: any[]) {
    const baggageScopes = scopes.filter(scope => scope.description.includes('Delay, loss or damage of baggage'));
    if (baggageScopes.length > 0) {
      return baggageScopes[0].isIncluded;
    } else {
      return false;
    }
  }

  redirectToBdo() {
    window.location.href = this.configService.getBdoInsureConfig().travel;
  }
}
