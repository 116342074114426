import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
  EPayment,
  HealthInsurance,
  HealthPolicyHolder,
  MotorExistingCustomer,
  MotorWizard,
  PolicyDetail,
  PrePurchase,
  RateCard,
  TravelInsurance
} from '@insureshop/shared/models';
import { HealthInsuranceClaim } from '@insureshop/shared/models/health-insurance-claim.model';
import { TravelInsuranceClaim } from '@insureshop/shared/models/travel-insurance-claim.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class InsuranceService {
  private headers = { headers: new HttpHeaders().set('exclude', 'true') };
  promoCode: string;

  constructor(private httpClient: HttpClient) {}

  public purchaseTravel(insurance: TravelInsurance): Observable<any> {
    return this.httpClient.post<any>('/api/v1/insurance/travel/purchase', insurance);
  }

  public purchaseHealth(insurance: HealthInsurance): Observable<HealthInsurance> {
    return this.httpClient.post<HealthInsurance>('/api/v1/insurance/health/purchase', insurance);
  }

  public payTravel(id: number, paymentType?: string): Observable<any> {
    let params = new HttpParams();
    if (paymentType) {
      params = params.set('paymentType', paymentType);
    }
    return this.httpClient.put<any>(
      `/api/v1/insurance/travel/pay/${id}`,
      null,
      { params }
    );
  }

  public payHealth(id: number, paymentType?: string): Observable<any> {
    let params = new HttpParams();
    if (paymentType) {
      params = params.set('paymentType', paymentType);
    }
    return this.httpClient.put<any>(
      `/api/v1/insurance/health/pay/${id}`,
      null,
      { params }
    );
  }

  public checkPaymentStatus(id: number): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/insurance/payment/status/insurance/${id}`);
  }

  public setPromoCode(promoCode: string) {
    this.promoCode = promoCode;
  }

  public getPromoCode() {
    return this.promoCode;
  }

  public paymentGateway(form: EPayment): Observable<any> {
    const payload = new FormData();
    payload.append('MerchantCode', form.merchantCode);
    payload.append('PaymentId', form.paymentId.toString());
    payload.append('RefNo', form.referenceNumber);
    payload.append('Amount', form.amount.toString());
    payload.append('Currency', form.currency);
    payload.append('ProdDesc', form.productDescription);
    payload.append('UserName', form.name);
    payload.append('UserEmail', form.emailAddress);
    payload.append('UserContact', form.mobileNumber);
    payload.append('Remark', form.remarks || '');
    payload.append('Lang', form.encoding);
    payload.append('Signature', form.signature);
    payload.append('ResponseURL', form.successRedirectURL);
    payload.append('BackendURL', form.successPostURL);

    return this.httpClient.post<any>(`/epayment/entry.asp`, payload);
  }

  public checkIfMinor(birthDate: Date): Observable<any> {
    let parameters = new HttpParams();
    parameters = parameters.set('birthDate', birthDate.toISOString());

    return this.httpClient.post<any>(`/api/v1/insurance/travel/check-if-minor`, parameters);
  }

  public calculateTravel(policyHolders: any): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/travel/calculate`,
      policyHolders,
      this.headers
    );
  }

  public calculateHealth(policyHolders: any): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/health/calculate`,
      policyHolders,
      this.headers
    );
  }

  public validateGift(id: number, activationKey: string): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/health/gift-recipient/${id}/activation-key/${activationKey}/validate`,
      null,
      this.headers
    );
  }

  public claimGift(id: number, activationKey: string, receiverInfo: HealthPolicyHolder): Observable<any> {
    return this.httpClient.put<any>(
      `/api/v1/insurance/health/gift-recipient/${id}/activation-key/${activationKey}/claim`,
      receiverInfo,
      this.headers
    );
  }

  public generateTravelReport(email: string) {
    const payload = new FormData();
    payload.append('email', email);

    return this.httpClient.post<any>(`/api/v1/insurance/travel/report`, payload);
  }

  public generateTravelClaimsReport(email: string) {
    const payload = new FormData();
    payload.append('email', email);

    return this.httpClient.post<any>(`/api/v1/insurance/travel-insurance-claim/report`, payload);
  }

  public generateMedicashReport(email: string) {
    const payload = new FormData();
    payload.append('email', email);

    return this.httpClient.post<any>(`/api/v1/insurance/health/report`, payload);
  }

  public generateMedicashRegistrationReport(email: string) {
    const payload = new FormData();
    payload.append('email', email);

    return this.httpClient.post<any>(`/api/v1/insurance/health/report/registration`, payload);
  }

  public generateMedicashClaimsReport(email: string) {
    const payload = new FormData();
    payload.append('email', email);

    return this.httpClient.post<any>(`/api/v1/insurance/health-insurance-claim/report`, payload);
  }

  public generateMotorReport(email: string): Observable<any> {
    const payload = new FormData();
    payload.append('email', email);

    return this.httpClient.post<any>(`/api/v1/insurance/motor/report`, payload);
  }

  public generateMotorPrepurchaseReport(email: string): Observable<any> {
    const payload = new FormData();
    payload.append('email', email);

    return this.httpClient.post<any>(
      `/api/v1/insurance/pre-purchase-inquiry/report`,
      payload
    );
  }

  public generateMotorQuotationReport(email: string): Observable<any> {
    const payload = new FormData();
    payload.append('email', email);

    return this.httpClient.post<any>(
      `/api/v1/insurance/motor/report/quotation`,
      payload
    );
  }

  public fileTravelInsuranceClaim(claimDetails: TravelInsuranceClaim): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/travel-insurance-claim/file`,
      claimDetails
    );
  }

  public fileHealthInsuranceClaim(claimDetails: HealthInsuranceClaim): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/health-insurance-claim/file`,
      claimDetails
    );
  }

  public getTravelInsuranceClaimDownloadReq(fileName: string): Observable<any> {
    return this.httpClient.get(
      `/api/v1/insurance/travel-insurance-claim/download/${fileName}`,
      {
        responseType: 'blob',
        headers: { exclude: 'true' }
      }
    );
  }

  public getHealthInsuranceClaimDownloadReq(fileName: string): Observable<any> {
    return this.httpClient.get(
      `/api/v1/insurance/health-insurance-claim/download/${fileName}`,
      {
        responseType: 'blob',
        headers: { exclude: 'true' }
      }
    );
  }

  public travelSendEmailNotification(id: number): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/travel-insurance-claim/${id}/submit`,
      {}
    );
  }

  public healthSendEmailNotification(id: number): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/health-insurance-claim/${id}/submit`,
      {}
    );
  }

  public healthMedicashCardValidation(form: object): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/health/medicash/validate`,
      form
    );
  }

  public healthMedicashCardActivation(form: object): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/health/medicash/activate`,
      form
    );
  }

  public getMotorRateCard(form: any): Observable<RateCard> {
    return this.httpClient.post<RateCard>(
      `/api/v1/insurance/motor/rate-card`,
      form,
      this.headers
    );
  }

  public calculateMotorInsurance(form: any): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/motor/calculate`,
      form,
      this.headers
    );
  }

  public validateMotorPurchasePrice(purchasePrice: number, modelId: number): Observable<boolean> {
    let params = new HttpParams();
    params = params.append('purchasePrice', purchasePrice.toString());
    params = params.append('modelId', modelId.toString());
    return this.httpClient.post<boolean>(
      `/api/v1/insurance/motor/validate-purchase-price`,
      null,
      {
        ...this.headers,
        params
      }
    );
  }

  public motorInsuranceSave(motorInsurance: any): Observable<any> {
    return this.httpClient.post<any>(`/api/v1/insurance/motor`, motorInsurance);
  }

  public motorValidation(id: number, info: any): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/motor/${id}/validate-existing-motor`,
      info
    );
  }

  public motorResumeLater(
    id: number,
    resumePoint: string,
    emailAddress: string,
    mobileNumber?: string
  ) {
    const payload = new FormData();
    if (mobileNumber) {
      payload.append('mobileNumber', mobileNumber);
    }

    payload.append('email', emailAddress);
    payload.append('resumePoint', resumePoint);

    return this.httpClient.post(
      `/api/v1/insurance/motor/${id}/resume-later`,
      payload
    );
  }

  public motorUploadAttachment(attachment: any, id: number): Observable<Response> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/motor/upload/${id}`,
      attachment
    );
  }

  public motorNotifExistingClient(id: number): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/motor/${id}/notify-pioneer`,
      null
    );
  }

  public motorCustomerInfo(form: MotorExistingCustomer) {
    return this.httpClient.post<MotorExistingCustomer>(
      `/api/v1/insurance/motor/customer-info`,
      form
    );
  }

  public purchaseMotor(insurance: any): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/motor/purchase`,
      insurance
    );
  }

  public payMotor(id: number, paymentType?: string): Observable<any> {
    let params = new HttpParams();
    if (paymentType) {
      params = params.set('paymentType', paymentType);
    }
    return this.httpClient.put<any>(`/api/v1/insurance/motor/pay/${id}`, null, { params });
  }

  public prepurchaseInquiry(inquiry: PrePurchase) {
    return this.httpClient.post<PrePurchase>(
      `/api/v1/insurance/pre-purchase-inquiry/inquire`,
      inquiry
    );
  }

  public prepurchaseUploadAttachment(id: number, attachment: File) {
    const payload = new FormData();
    payload.append('file', attachment);
    return this.httpClient.post(
      `/api/v1/insurance/pre-purchase-inquiry/upload/${id}`,
      payload
    );
  }

  public prepurchaseInquirySubmission(id: number) {
    return this.httpClient.post(
      `/api/v1/insurance/pre-purchase-inquiry/${id}/submit`,
      null
    );
  }

  public resumeMotorTransaction(resumeId: string): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/motor/resumeId/${resumeId}/validate`,
      null
    );
  }

  public getMotorInsuranceByCodeAndId(id: number, code: string): Observable<MotorWizard> {
    return this.httpClient.get<MotorWizard>(`/api/v1/insurance/motor/${id}/code/${code}/get`);
  }

  public verifyDriverBirthDate(date: Date): Observable<any> {
    let dateParam = date.toISOString();
    return this.httpClient.get<any>(`/api/v1/insurance/motor/driver/birth-date/verify?birthDate=${dateParam}`);
  }

  public verifyDrivingExperience(ln: string): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/insurance/motor/driver/driving-experience/verify?licenseNumber=${ln}`);
  }

  public getOfficialReceipt(id: number): Observable<Blob> {
    return this.httpClient.get(
      `/api/v1/insurance/content/insurance/${id}/official-receipt`,
      {
        responseType: 'blob',
        headers: { exclude: 'true' }
      }
    );
  }

  public requestSubscription(emailAddress: string, mobileNumber: string): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/admin/subscription`,
      {
        emailAddress,
        mobileNumber,
      }
    );
  }

  public getPolicy(id: number, insuranceId: number): Observable<Blob> {
    return this.httpClient.get(
      `/api/v1/insurance/content/insurance/${insuranceId}/policy-holder/${id}/policy`,
      {
        responseType: 'blob',
        headers: { exclude: 'true' }
      }
    );
  }

  public getInsuranceListByStatus(status: string): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/insurance/policy-holder?status=${status}`);
  }

  public getAccihealthInsuranceList(keycloakID: string, active: boolean): Observable<any> {
    const status = active ? 'active' : 'inactive';
    return this.httpClient.get<any>(`${environment.accihealthConfig.url}/api/v1/insurance/${keycloakID}/${status}`)
  }

  public getCtplInsuranceList(keycloakID: string, active: boolean): Observable<any> {
    const status = active ? 'active' : 'inactive';
    return this.httpClient.get<any>(`${environment.ctplConfig.url}/api/v1/insurance/${keycloakID}/${status}`)
  }

  public getInsuranceDetailById(id: number, product: string): Observable<PolicyDetail> {
    let productDetail = '';
    switch (product) {
      case 'MediCash':
        productDetail = 'health';
        break;
      case 'SafeTrip':
        productDetail = 'travel';
        break;
      default:
        productDetail = 'motor';
    }
    return this.httpClient.get<PolicyDetail>(`/api/v1/insurance/policy-holder/${productDetail}/${id}`);
  }

  public cancelInsurance(urlParam: string, id: number, data: object): Observable<any> {
    return this.httpClient.put<any>(`/api/v1/insurance/policy-holder/${urlParam}/${id}/cancel`, data);
  }

  public travelAmendmentRequest(data: any): Observable<any> {
    return this.httpClient.post<any>(`/api/v1/insurance/travel-amendment/file`, data);
  }

  public getSalesReport(startDate: any, endDate: any): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/insurance/insurance-dashboard/sales?startDate=${startDate}&endDate=${endDate}`);
  }

  public getPolicyHolderHistory(id: number): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/insurance/policy-holder-history/policy/${id}`);
  }

  public $reportStatus = new BehaviorSubject<any>({
    sending: false,
    sendSuccess: null,
    reportName: null
  });

  // Promo Code Service
  public applyPromoCode(payload: any, product: string): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/insurance/${product}/apply-promo`,
      payload
    );
  }

  getTransactionData(id: any): Observable<any> {
    return this.httpClient.get<any>(`/api/v1/insurance/${id}/transaction-data`);
  }

  public generateCtplTransactionReport(email: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.ctplConfig.url}/api/v1/cocaf/report/transaction/ctpl/email/${email}`);
  }

  public generateCtplProductionReport(email: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.ctplConfig.url}/api/v1/insurance/report/production/ctpl/${email}`);
  }

  public generateCtplFailedReport(email: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.ctplConfig.url}/api/v1/cocaf/report/failed/ctpl/email/${email}`);
  }

  public generateOoopsieSalesReport(email: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.accihealthConfig.url}/api/v1/insurance/report/ooopsie?email=${email}`);
  }

  public generateHospicashSalesReport(email: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.accihealthConfig.url}/api/v1/insurance/report/hospicash?email=${email}`);
  }
} 
