import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UtilService } from '@insureshop/core/services';
import { MaritalStatus } from '@insureshop/modules/insurance/enums';

@Component({
  selector: 'app-marital-status-selection',
  templateUrl: './marital-status-selection.component.html',
  styleUrls: ['./marital-status-selection.component.scss']
})
export class MaritalStatusSelectionComponent implements OnInit {

  @Input() control: FormControl;

  public options: object;

  constructor(private util: UtilService) { }

  ngOnInit() {
    this.options = this.util.enumToArray(MaritalStatus);
  }

}
