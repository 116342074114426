import { NgModule } from '@angular/core';

import { SharedModule } from '@insureshop/shared/shared.module';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { DataPrivacyComponent } from './data-privacy/data-privacy.component';
import { PublicRoutingModule } from './public-routing.module';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';

@NgModule({
  declarations: [
    ContactUsComponent,
    DataPrivacyComponent,
    CookiePolicyComponent,
    TermsConditionsComponent
  ],
  imports: [
    SharedModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    PublicRoutingModule
  ]
})
export class PublicModule { }
