import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { en_US, NzI18nService } from 'ng-zorro-antd';

@Component({
  selector: 'app-upload-invoice',
  templateUrl: './upload-invoice.component.html',
  styleUrls: ['./upload-invoice.component.scss']
})
export class UploadInvoiceComponent implements OnInit {
  @Input() control: FormControl;

  public selectedFile: File[] = [];
  public fileTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  constructor(private i18n: NzI18nService) { }

  ngOnInit() {
    window.scroll(0, 0);

    this.i18n.setLocale(en_US);

    this.selectedFile = this.control.value || [];
  }

  beforeUpload = (file: File): boolean => {
    this.selectedFile = [];

    if (this.fileTypes.indexOf(file.type) > -1 && file.size <= 3145728) {
      this.selectedFile = this.selectedFile.concat(file);
      this.control.setValue([...this.selectedFile]);
    } else {
      this.control.setErrors({'invalidFile': true});
    }
    return false;
  };

}
