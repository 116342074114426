import { HttpParams } from '@angular/common/http';
import { TableParams } from '@insureshop/shared/models/data-table.model';


export interface FilterParams {
  disabled?: boolean;
}

export class DataTableUtil {

  constructor() {}

  public buildParams(tableParams: TableParams, filters?: FilterParams): HttpParams {

    let params = new HttpParams();

    params = params.set('page', tableParams.page.toLocaleString());
    params = params.set('size', tableParams.size.toLocaleString());
    params = params.set('sort', tableParams.sort ? tableParams.sort + ',' + tableParams.sortDirection : '');

    if (filters) {
      if (filters.disabled !== undefined) {
        params = params.set('disabled', filters.disabled.toLocaleString());
      }
    }

    return params;
  }
}
