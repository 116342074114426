import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactUsComponent } from '@insureshop/public/contact-us/contact-us.component';
import { CookiePolicyComponent } from '@insureshop/public/cookie-policy/cookie-policy.component';
import { DataPrivacyComponent } from '@insureshop/public/data-privacy/data-privacy.component';
import { TermsConditionsComponent } from '@insureshop/public/terms-conditions/terms-conditions.component';

const routes: Routes = [
  { path: 'contact-us', component: ContactUsComponent, data: { title: 'Contact Us' } },
  { path: 'data-privacy', component: DataPrivacyComponent, data: { title: 'Data Privacy' } },
  { path: 'cookie-policy', component: CookiePolicyComponent, data: { title: 'Cookie Policy' } },
  { path: 'terms-conditions', component: TermsConditionsComponent, data: { title: 'Terms & Conditions' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule { }
