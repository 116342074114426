import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-online-terms-conditions-modal',
  templateUrl: './online-terms-conditions-modal.component.html',
  styleUrls: ['./online-terms-conditions-modal.component.scss']
})
export class OnlineTermsConditionsModalComponent {
  @Input() agree;

  constructor(public activeModal: NgbActiveModal) { }

}
