import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor() {}

  getAccihealthConfig() {
    return environment.accihealthConfig;
  }

  getCtplConfig() {
    return environment.ctplConfig;
  }

  getBdoInsureConfig() {
    return environment.bdoInsureConfig;
  }
}
