import { Injectable } from '@angular/core';
import * as Stomp from 'stompjs';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SocketService {
    private stompClient: any;
    private ws: any;
    connectionStatus = new BehaviorSubject({});

    constructor() {}

    init(socketURL: string, subscribeURL: string, callback: any, errorCallback?: any) {
        this.ws = new WebSocket(socketURL);
        this.stompClient = Stomp.over(this.ws);
        const that = this;
        this.connectionStatus.next('started');
        this.stompClient.connect({}, function() {
            that.connectionStatus.next('connected');
            that.stompClient.subscribe(subscribeURL, (message: any) => {
                callback(message);
            });
        }, function() {
            that.connectionStatus.next('disconnected');
            errorCallback();
        });
    }

    publish(publishURL: string, data: any) {
        this.stompClient.send(publishURL, {}, data);
    }

    disconnect() {
        this.ws.close();
    }
}
