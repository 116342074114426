import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OTP } from '@insureshop/shared/models';
import { Observable } from 'rxjs';

@Injectable()
export class UtilService {
  constructor(private httpClient: HttpClient) {}

  public enumToArray(obj: any): any[] {
    return Object.keys(obj).map(key => ({ value: key, label: obj[key] }));
  }

  public sendOtp(contactDetails: OTP): Observable<OTP> {
    contactDetails.mobileNumber = '63' + contactDetails.mobileNumber;
    return this.httpClient.post<OTP>(`/api/v1/admin/otp`, contactDetails, {
      headers: { exclude: 'true' }
    });
  }

  public verifyOtp(id: number, code: string): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/admin/otp/${id}/code/${code}/verify`,
      null,
      { headers: { exclude: 'true' } }
    );
  }

  public randomNumber(num: number) {
    return Math.floor(Math.random() * Math.floor(num));
  }
}
