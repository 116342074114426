import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MbscCalendarOptions } from '@mobiscroll/angular';
import { Moment } from 'moment';

import * as moment from 'moment';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit, OnChanges {

  @Input() control: FormControl;
  @Input() min: Moment = moment();
  @Input() max: Moment = moment();
  @Input() placeholder: string = 'Birthdate';
  @Input() required: boolean = true;
  @Input() sales: boolean = false;
  @Input() dateHeader: string;
  @Output() set: EventEmitter<any> = new EventEmitter();
  @Output() dateChange: EventEmitter<any> = new EventEmitter();

  calendarSettings: MbscCalendarOptions;

  constructor() { }

  ngOnChanges() {
    
    const dateFormat = !this.sales ? 'M-dd-yy' : 'MM d, yy'
  
    this.calendarSettings = {
      theme: 'bootstrap',
      animate: 'pop',
      display: 'center',
      touchUi: false,
      buttons: ['set'],
      showLabel: false,
      headerText: this.dateHeader,
      counter: true,
      defaultValue: null,
      dateFormat,
      returnFormat: 'locale',
      min: this.min.subtract(1, 'year').add(1, 'day'),
      max: this.max.toDate()
    };
  }

  ngOnInit() {
  }

  dateInputChange(value: any) {
    this.dateChange.emit(value);
  }

}
