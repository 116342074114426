import { NgModule } from '@angular/core';

// uncomment as needed
import {
  MatButtonModule,
  MatCheckboxModule,
  MatAutocompleteModule,
  MatButtonToggleModule,
  MatCardModule,
  // MatChipsModule,
  // MatDatepickerModule,
  // MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  // MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  // MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  // MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  // MatSlideToggleModule,
  MatSliderModule,
  // MatSnackBarModule,
  // MatSortModule,
  MatStepperModule,
  // MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';


@NgModule({
  imports: [
    MatButtonModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatCardModule,
    // MatChipsModule,
    // MatDatepickerModule,
    // MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    // MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    // MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    // MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    // MatSlideToggleModule,
    MatSliderModule,
    // MatSnackBarModule,
    // MatSortModule,
    MatStepperModule,
    // MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
  ],
  exports: [
    MatButtonModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatCardModule,
    // MatChipsModule,
    // MatDatepickerModule,
    // MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    // MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    // MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    // MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    // MatSlideToggleModule,
    MatSliderModule,
    // MatSnackBarModule,
    // MatSortModule,
    MatStepperModule,
    // MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
  ],
})
export class MaterialModule { }
