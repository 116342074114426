// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let keycloakConfig = {
  clientId: 'insureshop-app',
  realm: 'insureshop',
  url: 'https://keycloak.pioneerinsureshop.com/auth',
  credentials: {
    secret: '5d9dc648-7067-4268-b902-b58520f6b711'
  }
}

let accihealthConfig = {
  url: 'https://accihealth-core.test.service.pioneerinsureshop.com',
  hospicash: 'https://accihealth-ui.test.service.pioneerinsureshop.com/product/hospicash',
  ooopsie: 'https://accihealth-ui.test.service.pioneerinsureshop.com/product/ooopsie'
}

let ctplConfig = {
  url: 'https://ctpl-core.test.service.pioneerinsureshop.com',
  ctpl: 'https://accihealth-ui.test.service.pioneerinsureshop.com/motor/ctpl',
  motorProducts: 'https://accihealth-ui.test.service.pioneerinsureshop.com/motor/products'
}

let bdoInsureConfig = {
  url: 'https://www.bdo.com.ph/bdoinsure',
  travel: "https://www.bdo.com.ph/bdoinsure/travel"
}

export const environment = {
  production: false,
  keycloakConfig: keycloakConfig,
  accihealthConfig: accihealthConfig,
  ctplConfig: ctplConfig,
  bdoInsureConfig: bdoInsureConfig
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
