import { NgModule } from '@angular/core';
// uncomment as needed
import {
  // NgbAccordion,
  NgbAlertModule,
  NgbButtonsModule,
  NgbCarouselModule,
  // NgbCollapseModule,
  // NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbPaginationModule,
  NgbPopoverModule,
  NgbProgressbarModule,
  // NgbRatingModule,
  // NgbTabsetModule,
  // NgbTimepickerModule,
  // NgbTooltipModule,
  // NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  imports: [
    // NgbAccordion,
    NgbAlertModule,
    NgbButtonsModule,
    NgbCarouselModule,
    // NgbCollapseModule,
    // NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPaginationModule,
    NgbPopoverModule,
    NgbProgressbarModule,
    // NgbRatingModule,
    // NgbTabsetModule,
    // NgbTimepickerModule,
    // NgbTooltipModule,
    // NgbTypeaheadModule
  ],
  exports: [
    // NgbAccordion,
    NgbAlertModule,
    NgbButtonsModule,
    NgbCarouselModule,
    // NgbCollapseModule,
    // NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPaginationModule,
    NgbPopoverModule,
    NgbProgressbarModule,
    // NgbRatingModule,
    // NgbTabsetModule,
    // NgbTimepickerModule,
    // NgbTooltipModule,
    // NgbTypeaheadModule
  ],
})
export class NgBootstrapModule { }
