import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { PROPAGATION_FRAME_ID } from './shared/constants/session-propagation-constants';
import { filter, map } from 'rxjs/operators';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'InsureShop';
  public sessionPropagationFrameId: string

  constructor(
    public router: Router,
    private ccService: NgcCookieConsentService,
    private titleService: Title,
    private activatedRouteService : ActivatedRoute
  ) {
    this.sessionPropagationFrameId = PROPAGATION_FRAME_ID
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
          let child = this.activatedRouteService.firstChild;
          while (child) {
              if (child.firstChild) {
                  child = child.firstChild;
              } else if (child.snapshot.data &&    child.snapshot.data['title']) {
                  return child.snapshot.data['title'];
              } else {
                  return null;
              }
          }
          return null;
      })
  ).subscribe( (data: any) => {
      if (data) {
          this.titleService.setTitle(data);
      }
  });
  }
}
