export class EPayment {
  constructor(
    public host?: string,
    public merchantCode?: string,
    public paymentId?: number,
    public referenceNumber?: string,
    public amount?: number,
    public currency?: string,
    public productDescription?: string,
    public name?: string,
    public emailAddress?: string,
    public mobileNumber?: string,
    public remarks?: string,
    public encoding?: string,
    public signature?: string,
    public successRedirectURL?: string,
    public successPostURL?: string
  ) {}
}
