import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@insureshop/shared/components/404/page-not-found.component';
import { PublicLayoutComponent } from '@insureshop/shared/components/layout/base/public-layout.component';
import * as AppRoutes from './app.routes';

/* Production build does not allow functions as URL matchers; solution was to set route constants instead;
* see: https://github.com/angular/angular/issues/18662
*/
const routes: Routes = [
  {
    path: '', component: PublicLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: AppRoutes.HEALTH_URL,
        pathMatch: 'full'
      },
      {
        path: AppRoutes.TRAVEL_URL,
        data: { title: 'Travel Insurance' },
        loadChildren: './modules/insurance/travel/travel-insurance.module#TravelInsuranceModule'
      },
      {
        path: AppRoutes.HEALTH_URL,
        data: { title: 'Dengue Insurance' },
        loadChildren: './modules/insurance/health-accident/health-insurance.module#HealthInsuranceModule'
      },
      {
        path: 'admin',
        data: { title: 'Admin' },
        loadChildren: './modules/admin/admin.module#AdminModule'
      }
    ]
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
