import { BillingService } from '@insureshop/core/services/billing.service';
import { CmsService } from '@insureshop/core/services/cms.service';
import { InsuranceService } from '@insureshop/core/services/insurance.service';
import { ReferenceService } from '@insureshop/core/services/reference.service';
import { MockDataService } from './mock-data.service';
import { UtilService } from './util.service';
import { SocketService } from '@insureshop/core/services/socket.service';

export {
  BillingService,
  CmsService,
  InsuranceService,
  MockDataService,
  ReferenceService,
  UtilService,
  SocketService
};

export const CoreServices = [
  BillingService,
  CmsService,
  InsuranceService,
  MockDataService,
  ReferenceService,
  UtilService,
  SocketService
];
