import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilService } from '@insureshop/core/services';
import { Suffix } from '@insureshop/modules/insurance/enums';
import { NAME_FORMAT } from '@insureshop/shared/constants/regex-patterns';

@Component({
  selector: 'app-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss']
})
export class NameComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() submitted: boolean;
  @Input() required: boolean = true;
  public options: any;

  constructor(private util: UtilService, private fb: FormBuilder) {}

  ngOnInit() {
    this.options = this.util.enumToArray(Suffix);
  }

  get firstName() { return this.formGroup.get('firstName'); }
  get middleName() { return this.formGroup.get('middleName'); }
  get lastName() { return this.formGroup.get('lastName'); } 
  get suffix() { return this.formGroup.get('suffix'); }
}
