import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '@insureshop/core/services';
import {
  CONTACT_NUMBER_FORMAT,
  EMAIL_FORMAT,
  NAME_FORMAT
} from '@insureshop/shared/constants/regex-patterns';
import { OTP, Payor } from '@insureshop/shared/models';
import { User } from '@insureshop/shared/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit {
  @Input() data: Payor;
  @Input() medicashFields: boolean;
  @Input() isUserProfile: boolean;
  @Input() purchaseProcess: string;
  @Output() complete: EventEmitter<any> = new EventEmitter();
  @Input() product: string;

  public otp = new FormControl(null);
  public sending = false;
  public verifying = false;

  public form: FormGroup;
  public min = moment().subtract(200, 'year');
  public max = moment().subtract(18, 'year');

  constructor(
    private fb: FormBuilder,
    private us: UtilService,
    private ms: NgbModal,
    private ar: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.data) {
      this.buildForm(this.data);
    } else {
      this.data = new Payor();
      this.buildForm(this.data);
      this.data.otpVerified = false;
    }  
  }


  buildForm(data: Payor) {
    this.form = this.fb.group({
      name: this.fb.group({
        firstName: [
          (data.name && data.name.firstName) || null,
          [Validators.required, Validators.pattern(NAME_FORMAT)]
        ],
        middleName: [
          (data.name && data.name.middleName) || null,
          [Validators.pattern(NAME_FORMAT)]
        ],
        lastName: [
          (data.name && data.name.lastName) || null,
          [Validators.required, Validators.pattern(NAME_FORMAT)]
        ],
        suffix: [(data.name && data.name.suffix) || null]
      }),
      emailAddress: [
        data.emailAddress || null,
        [Validators.required, Validators.pattern(EMAIL_FORMAT), Validators.maxLength(50)]
      ],
      mobileNumber: [
        data.mobileNumber ? data.mobileNumber.slice(-10) : null,
        [Validators.required, Validators.pattern(CONTACT_NUMBER_FORMAT)]
      ],
      gender: [
        data.gender || null,
        this.medicashFields ? Validators.required : []
      ],
      maritalStatus: [
        data.maritalStatus || null,
        this.medicashFields ? Validators.required : []
      ],
      birthDate: [
        data.birthDate || null,
        this.medicashFields ? Validators.required : []
      ],
      address: this.fb.group({
        street: [
          (data.address && data.address.street) || null,
          [Validators.required]
        ],
        city: [
          (data.address && data.address.city) || null,
          Validators.required
        ],
        province: [
          (data.address && data.address.province) || null,
          Validators.required
        ],
        barangay: [
          (data.address && data.address.street) || null,
          Validators.required
        ],
        zipCode: this.fb.group({
          id: [null],
          code: ['']
        })
      })
    });
    if(this.product == 'RideSure') {
      this.form.get('address').get('street').setValidators([Validators.required, Validators.maxLength(50)]);
    }
    this.data.mobileNumber = this.data.mobileNumber
      ? data.mobileNumber.slice(-10)
      : null;
  }

  get address() {
    return this.form.get('address');
  }
  get birthDate() {
    return this.form.get('birthDate');
  }
  get gender() {
    return this.form.get('gender');
  }
  get maritalStatus() {
    return this.form.get('maritalStatus');
  }
  get name() {
    return this.form.get('name');
  }

  verifyUser() {
    if (this.ar.snapshot.queryParamMap.has('skipOtp')) {
      this.submit();
      return;
    }

    if (this.data.otpVerified) {
      // verify if the user's mobile and/or email are still the same
      if (
        this.form.value.mobileNumber !== this.data.mobileNumber ||
        this.form.value.emailAddress !== this.data.emailAddress
      ) {
        this.sendOtp();
      } else {
        this.submit();
      }
    } else {
      this.sendOtp();
    }
  }

  verifyOtp() {
    this.verifying = true;
    this.us.verifyOtp(this.data.otp.id, this.otp.value).subscribe(
      response => {
        this.data.otpVerified = response.verified;

        if (this.data.otpVerified) {
          this.submit();
        } else {
          this.otp.setErrors({ invalid: true });
        }

        this.verifying = false;
      },
      error => {
        this.verifying = false;
        console.log(error);
      }
    );
  }

  sendOtp() {
    this.sending = true;
    const contactDetails = new OTP(
      null,
      this.form.value.mobileNumber,
      this.form.value.emailAddress
    );
    this.us.sendOtp(contactDetails).subscribe(
      (otp: OTP) => {
        this.data.otp = otp;
        this.data.otpVerified = false;
        this.otp.patchValue('');
        this.sending = false;
      },
      error => {
        this.sending = false;
        console.log(error);
      }
    );
  }

  submit() {
    const userInfo = Object.assign(this.data, this.form.value);
    this.complete.emit(userInfo);
  }
}



