import { Name } from '@insureshop/shared/models/name.model';
import { Payor } from '@insureshop/shared/models/payor.model';
import {Address} from '@insureshop/shared/models/address.model'
import { PriceBreakdown } from '@insureshop/shared/models/price-breakdown.model';

export class MotorInsurance {
  constructor(
    public motorModelId: number,
    public motorPurchasePrice: number,
    public ageRange: string,
    public drivingExperience: string,
    public usageType: string,
    public purchaseType: string,
    public actsOfNaturePercentCoverage: number,
    public tpbi: boolean,
    public tppd: boolean,
    public personalAccident: boolean,
    public payor: Payor,
    public policyHolder: {
      name: Name;
      gender: string;
      birthDate: Date;
      address: Address;
      emailAddress: string;
      mobileNumber: number;
    },
    public driver: {
      name: Name;
      gender: string;
      birthDate: Date;
      licenseNumber: string;
    },
    public engineNumber?: string,
    public chassisNumber?: string,
    public plateNumber?: string,
    public id?: number,
    public promoCode?: string,
    public discount?: number
  ) {}
}
