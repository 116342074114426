import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const CookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname
  },
  position: 'bottom',
  theme: 'classic',
  palette: {
    popup: {
      background: '#0086CD',
      text: '#ffffff',
      link: '#ffffff'
    },
    button: {
      background: '#e5dddd',
      text: '#353536',
      border: 'transparent'
    }
  },
  type: 'info',
  content: {
    message: '<span style=\'font-family: Nunito, sans-serif; font-size: 15px;\'>This website uses cookies to collect information about how you interact with our website. We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors. To find out more, see our Privacy Policy. If you continue to use this site, you consent to our use of cookies.</span>',
    dismiss: 'Got it!',
    deny: 'Refuse cookies',
    link: '<span style=\'font-family: Nunito, sans-serif; font-size: 15px;\'>Learn more</span>',
    href: 'https://cookiesandyou.com',
    policy: 'Cookie Policy'
  }
};