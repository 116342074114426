import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InsuranceService } from '@insureshop/core/services';
import { MotorWizard } from '@insureshop/shared/models';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-resend-link',
  templateUrl: './resend-link.component.html',
  styleUrls: ['./resend-link.component.scss']
})
export class ResendLinkComponent implements OnInit {
  @Input() data: any;

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private insuranceService: InsuranceService
  ) {}

  ngOnInit() {}

  resendLink() {
    this.data.source.subscribe(
      res => console.log('Success'),
      err => console.log('Error: ', err)
    );      
  }

  goToHomepage() {
    this.activeModal.close();
    this.router.navigateByUrl('/');
  }
}
