import { Address } from '@insureshop/shared/models/address.model';
import { Name } from '@insureshop/shared/models/name.model';

export class HealthPolicyHolder {
  constructor(
    public name: Name,
    public gender: string,
    public birthDate: Date,
    public birthPlace: string,
    public nationality: string,
    public tin: string,
    public sssOrGsis: string,
    public employmentType: string,
    public natureOfWork: string,
    public companyName: string,
    public emailAddress: string,
    public mobileNumber: number,
    public address: Address,
    public maritalStatus?: string
  ) {}
}
