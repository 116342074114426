import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { CmsService } from '@insureshop/core/services';
import { ConfigService } from '@insureshop/core/services/config.service';
import { User } from '@insureshop/shared/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, OnDestroy {
  public products = [];
  public header: any;
  bdoInsureLink: any;

  constructor(private cmsService: CmsService,
    private modalService: NgbModal,
    private configService: ConfigService) {
  }

  ngOnInit() {
    this.bdoInsureLink = this.configService.getBdoInsureConfig().url;
    this.cmsService.getProductOfferings()
      .pipe(untilComponentDestroyed(this))
      .subscribe(products => this.products = products);

    this.cmsService.getHeader()
      .pipe(untilComponentDestroyed(this))
      .subscribe(header => this.header = header);
  }

  goBack() {
    window.location.href = this.bdoInsureLink;
  }

  ngOnDestroy() { }
}
